<template>
  <div :class="{ 'list-wrapper': !window, container: !window }">
    <slot name="header"></slot>
    <div class="list-wrapper-inner">
      <product-scroll
        :getList="getList"
        :page="pagination?.current_page"
        :lastPage="pagination?.last_page"
        :initLoading="initLoading"
        :window="window"
      >
        <template v-if="list.length !== 0">
          <!-- <component :is="node" /> -->
          <slot />
        </template>
        <template v-else>
          <no-info-block :info="info" />
        </template>
      </product-scroll>
    </div>
    <copy-right />
  </div>
</template>

<script>
// import { h } from "vue";
import ProductScroll from "@/components/ProductScroll.vue";
import CopyRight from "@/components/CopyRight.vue";
import NoInfoBlock from "@/components/NoInfoBlock.vue";
export default {
  components: {
    ProductScroll,
    CopyRight,
    NoInfoBlock,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    getList: Function,
    pagination: {
      type: Object,
      default: () => ({}),
    },
    initLoading: {
      type: Boolean,
      default: false,
    },
    window: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
    },
  },
  setup(props, { slots }) {
    // let node = slots.default && slots.default()[0];
    // return {
    //   node: h(node, { list: props.list }),
    // };
  },
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .list-wrapper-inner {
    flex: 1;
    overflow-y: auto;
    z-index: 2;
  }
}
</style>
