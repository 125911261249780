<template>
  <div class="index-coupon-bar">
    <div class="tab-bar" v-for="(bar, index) in bars" :key="index">
      <div
        class="bar center"
        v-for="item in bar"
        :key="item.path"
        :class="{ active: currentBar === item.path }"
        @click="itemClick(item)"
      >
        <p class="text">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  props: {
    bars: {
      type: Array,
      default: () => [],
    },
    currentBar: {
      type: String,
      default: "",
    },
  },
  emits: ["update:currentBar"],
  setup(props, { emit }) {
    const itemClick = (item) => {
      emit("update:currentBar", item.path);
    };

    return {
      itemClick,
    };
  },
};
</script>

<style scoped lang="scss">
.index-coupon-bar {
  // display: flex;
  // justify-content: space-around;
}

.tab-bar {
  width: 100%;
  display: inline-flex;
  box-shadow: 0px 0px 6px #00000033;
  border-radius: 10px;
  .bar {
    width: 50%;
    padding: 8px 24px;
    border-left: 1px solid var(--color-border);
    cursor: pointer;

    @media (max-width: 525px) {
      padding: 8px 12px;
    }

    .text {
      margin: 0;
      color: #9b9b9b;
      white-space: nowrap;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .active {
    background-color: var(--color-primary);

    .text {
      color: #fff;
    }
  }
}
</style>
