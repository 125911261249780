<template>
  <div class="block">
    <div class="no-info center">
      <img src="@/assets/images/sms_failed-24px.png" alt="logo" />
      <h4>{{ info }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: String,
      default: "目前尚無資訊!",
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.block {
  height: 100%;

  .no-info {
    min-height: 300px;
    flex-direction: column;
    background-color: var(--color-pale-primary);
    color: var(--color-primary);
    height: 100%;

    img {
      width: 40px;
      margin-bottom: 16px;
    }
  }
}
</style>
