<template>
  <coupon-item
    :isUsed="true"
    v-for="item in list"
    :key="item.id"
    :item="item"
    style="margin-bottom: 12px"
  />
</template>

<script>
// import ListWrapper from "@/components/ListWrapper.vue";
import CouponItem from "@/components/CouponItem.vue";
export default {
  components: {
    // ListWrapper,
    CouponItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
