<template>
  <list-wrapper
    :list="list"
    :getList="getList"
    :pagination="pagination"
    :initLoading="isLoading"
  >
    <template #header>
      <content-title title="歷史紀錄" />
      <coupon-bar
        style="padding-bottom: 16px"
        :bars="bars"
        v-model:currentBar="currentBar"
      ></coupon-bar>
    </template>
    <coupon-record-list :list="list" />
  </list-wrapper>
</template>

<script>
import { ref, watch } from "vue";
import CouponBar from "@/components/CouponBar.vue";
import ContentTitle from "@/components/ContentTitle.vue";
import CouponSevice from "@/services/coupon.service";
import ListWrapper from "@/components/ListWrapper.vue";

import CouponRecordList from "./components/CouponRecordList.vue";

export default {
  components: {
    CouponBar,
    ContentTitle,
    CouponRecordList,
    ListWrapper,
  },
  setup() {
    const currentBar = ref("2");
    const bars = [
      [
        {
          name: "門市優惠券",
          path: "2",
        },
        {
          name: "線上優惠券",
          path: "1",
        },
      ],
    ];

    const pagination = ref({});
    const list = ref([]);
    const isLoading = ref(true);

    const getList = async (page) => {
      const result = await CouponSevice.getCouponHistory(
        page,
        10,
        currentBar.value
      );
      pagination.value = result.pagination;
      if (result.data?.length) {
        list.value.push(...result.data);
      }
    };

    const initList = async () => {
      isLoading.value = true;
      list.value = [];
      await getList(1);
      isLoading.value = false;
    };

    watch(
      () => currentBar.value,
      async () => {
        await initList();
      },
      {
        immediate: true,
      }
    );

    return {
      bars,
      currentBar,
      list,
      getList,
      pagination,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped></style>
